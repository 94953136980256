<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">CRM </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Attività</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>


    <div class="mt-2">

        <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" />
          </div>
        </b-col>
      </b-row>
        
        <b-table
        :fields="fields"
        :items="resellers"
        :busy="loading"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        striped
        show-empty
        responsive
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle attività...</strong>
          </div>
        </template>

        <template #empty>
          <h5 class="text-primary-light text-center my-2">Non ci sono attività</h5>
        </template>

        <template #emptyfiltered>
          <h5 class="text-primary-light text-center my-2">Non sono state trovate attività</h5>
        </template>

        <template #cell(opzioni)>
          <feather-icon class="mr-2" icon="EyeIcon" size="20" />
          <feather-icon icon="EditIcon" size="20" />
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        align="right"
        size="sm"
        class="my-0"
      />

    </div>



    </b-card>
  </section>
</template>

<script>

import { BRow, BCol, BBreadcrumb, BBreadcrumbItem, BCard, BTable, BPagination, BFormInput } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BTable,
    BPagination,
    BFormInput,
  },

  data: () => ({

      fields: [
          {
              key: "creato_da",
              sortable: true,
          },
          {
              key: "data_attività",
              sortable: true,
          },
          {
              key: "tipo_attività",
              sortable: true,
          },
          {
              key: "nome_e_cognome_lead",
              sortable: true,
          },
          {
              key: "opzioni",
              sortable: false,
          },
      ],

      //Helpers

      loading: false,
      currentPage: 1,
      perPage: 15,
      filter: '',


  })
}

</script>


